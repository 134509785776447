<!-- Cart Style Basic -->
<app-button
  [type]="'button'"
  [class]="'btn p-0 position-relative header-wishlist'"
  [spinner]="false"
  [id]="'cart_sidebar_btn'"
  (click)="cartStyle == 'cart_sidebar' && cartToggle(true)" *ngIf="style == 'basic'">
    <i class="ri-shopping-cart-line"></i>
    <span class="position-absolute top-0 start-100 translate-middle badge" *ngIf="(cartItem$ | async)?.length">
        {{ (cartItem$ | async)?.length }} <span class="visually-hidden">{{ 'unread_messages' | translate }}</span>
    </span>
</app-button>

<!-- Cart Style Classic -->
<a href="javascript:void(0)" class="header-icon swap-icon"
    (click)="cartStyle == 'cart_sidebar' && cartToggle(true)"
    *ngIf="style == 'classic'">
    <small class="badge-number badge-light" *ngIf="(cartItem$ | async)?.length">
        {{ (cartItem$ | async)?.length }}
    </small>
    <i class="ri-shopping-cart-line"></i>
</a>

<!-- Cart On Hover -->
<div class="onhover-div"
    [ngClass]="{ 'fixed-cart': cartStyle == 'cart_sidebar' }"
    [class.show]="(sidebarCartOpen$ | async)">
    <div class="cart-title">
        <h4>{{ 'shopping_cart' | translate }}</h4>
        <a href="javascript:void(0)" (click)="cartToggle(false)">
            <i class="ri-close-line"></i>
        </a>
    </div>
    <div class="pere-text-box success-box" *ngIf="(cartItem$ | async)?.length">
        <p *ngIf="shippingFreeAmt > cartTotal else content">
            {{ 'spend' | translate }} <span class="shipping">{{ (shippingFreeAmt - cartTotal) | currencySymbol }}</span> {{ 'more_and_enjoy' | translate }} <span class="shipping">{{ 'free_shipping' | translate }}!</span>
        </p>
        <ng-template #content>
            <p><span class="shipping">{{ 'congratulations' | translate }}!</span> {{ 'enjoy_free_shipping_on_us' | translate }}!</p>
        </ng-template>
      <div class="progress"
        [ngClass]="{ 'danger-progress': shippingCal <= 30, 'warning-progress': shippingCal >= 31 && shippingCal <= 80 }">
        <div class="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
            [ngStyle]="{ width: shippingCal+'%' }">
          <i class="ri-truck-line"></i>
        </div>
      </div>
    </div>
    <ul class="cart-list" *ngIf="(cartItem$ | async)?.length">
        <li class="product-box-contain" *ngFor="let item of cartItem$ | async">
            <div class="drop-cart">
                <a href="javascript:void(0)" class="drop-image">
                    <img [src]="item?.variation && item?.variation?.variation_image
                            ? item?.variation?.variation_image?.original_url
                            : item?.product?.product_thumbnail
                            ? item?.product?.product_thumbnail?.original_url
                            : 'assets/images/product.png'" class="img-fluid" [alt]="item?.product?.name">
                </a>
                <div class="drop-contain">
                    <a href="javascript:void(0)">
                        <h5>{{ item?.variation ? item?.variation?.name : item?.product?.name }}</h5>
                    </a>
                    <h6>{{ (item?.variation ? item.variation.sale_price : item.product.sale_price) | currencySymbol }}</h6>
                    <h5 class="gram" *ngIf="item?.variation">
                        {{ item?.variation?.selected_variation }}
                    </h5>
                    <div class="cart_qty qty-box product-qty">
                      <div class="input-group h-100">
                        <app-button
                            [class]="'btn qty-left-minus'"
                            [id]="'add-to-cart'+item.product.id"
                            [spinner]="false"
                            (click)="updateQuantity(item, -1)">
                            <i class="ri-subtract-line" *ngIf="item.quantity > 1"></i>
                            <i class="ri-delete-bin-line" *ngIf="item.quantity <= 1"></i>
                        </app-button>
                        <input class="form-control input-number qty-input" type="number" name="quantity" [value]="item?.quantity" readonly>
                        <app-button
                            [class]="'btn qty-left-minus'"
                            [id]="'qty-left-minus'+item.product.id"
                            [spinner]="false"
                            (click)="updateQuantity(item, 1)">
                            <i class="ri-add-line"></i>
                        </app-button>
                      </div>
                    </div>
                    <div>
                        <div class="header-button-group">
                            <app-button [class]="'edit-button close_button'" [id]="'cart_item_edit_btn'+item.product.id" [spinner]="false" (click)="VariationModal.openModal(item)" *ngIf="item?.variation">
                                <i class="ri-pencil-line"></i>
                            </app-button>
                            <app-button [class]="'delete-button close_button'" [id]="'cart_item_delete_btn'+item.product.id" [spinner]="false" (click)="delete(item.id)">
                                <i class="ri-delete-bin-line"></i>
                            </app-button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
    <div class="empty-cart-box" *ngIf="!(cartItem$ | async)?.length">
        <i class="ri-shopping-cart-line"></i>
        <h5>{{ 'your_cart_is_currently_empty' | translate }}</h5>
    </div>
    <div class="bottom-box" *ngIf="(cartItem$ | async)?.length">
        <p class="free">{{ 'shipping_and_taxes_are_calculated_at_checkout' | translate }}</p>
        <div class="price-box">
            <h5>{{ 'total' | translate }}:</h5>
            <h4 class="fw-bold">{{ (cartTotal$ | async)! | currencySymbol }}</h4>
        </div>
        <div class="button-group">
            <a [routerLink]="['/cart']" class="btn btn-sm cart-button" (click)="cartToggle(false)">
                {{ 'view_cart' | translate }}
            </a>
            <a [routerLink]="['/checkout']" class="btn btn-sm cart-button theme-bg-color text-white" (click)="cartToggle(false)">
                {{ 'checkout' | translate }}
            </a>
        </div>
    </div>
</div>
<div class="bg-overlay" [class.show]="cartStyle == 'cart_sidebar' && (sidebarCartOpen$ | async)" (click)="cartStyle == 'cart_sidebar' && cartToggle(false)"></div>
<!-- Free Shipping Celebration -->
<div class="confetti-wrapper" [class.show]="confetti == 1 && (sidebarCartOpen$ | async)">
    <ng-container *ngFor="let item of confettiItems">
        <div class="confetti-{{item}}"></div>
    </ng-container>
</div>
<!-- Variation Customize -->
<app-variation-modal #variationModal></app-variation-modal>

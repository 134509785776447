<ng-template #confirmationModal let-modal>
    <div class="modal-body">
        <i class="ri-question-line icon-box wo-bg"></i>
        <h5 class="modal-title">{{ 'confirmation' | translate }}</h5>
        <p>{{ 'are_you_sure_you_want_to_proceed' | translate }}?</p>
        <div class="button-box">
            <app-button
                [class]="'btn btn-md fw-bold btn-theme-outline'"
                [spinner]="false"
                [id]="'confirm_no_btn'"
                (click)="modal.dismiss('Cancel')">
                {{ 'no' | translate }}
            </app-button>
            <app-button
                [class]="'btn theme-bg-color btn-md fw-bold text-light'"
                [id]="'confirm_yes_btn'"
                (click)="confirm()">
                {{ 'yes' | translate }}
            </app-button>
        </div>
    </div>
</ng-template>

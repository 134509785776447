<ng-container *ngFor="let attribute of attributes; let i = index;">
  <div class="product-title" *ngIf="!isAllVariantStyleDropdown">
      <h4>{{ attribute?.name }} : {{ attribute?.selected_value }}</h4>
  </div>
  <ng-container *ngIf="attribute?.style == 'dropdown' || isAllVariantStyleDropdown else variantRadio">
    <select id="input-state-{{i}}" class="form-control form-select" #selectedValue
        (change)="setVariant(product.variations, attribute.attribute_values[$any($event.target).value])">
        <option selected disabled>{{ 'choose' | translate }} {{ attribute?.name }}</option>
        <ng-container *ngFor="let value of attribute.attribute_values; let indexx = index;">
            <option [selected]="variantIds.includes(value.id) && !soldOutAttributesIds.includes(value.id)" [value]="indexx"
                [disabled]="soldOutAttributesIds.includes(value.id)"
                *ngIf="attributeValues?.includes(value.id)">
                {{ value?.value }}
            </option>
        </ng-container>
    </select>
  </ng-container>
  <ng-template #variantRadio>
      <ng-container *ngIf="attribute?.style == 'radio' else variantSimple">
          <div class="d-flex">
              <ng-container *ngFor="let value of attribute.attribute_values; let indexx = index;">
                  <div class="form-check" *ngIf="attributeValues?.includes(value.id)">
                      <input type="radio" class="form-check-input" [id]="'radio-' + i + '-' + indexx"
                          [name]="'radio-group-' + i" [value]="indexx"
                          [checked]="variantIds.includes(value.id) && !soldOutAttributesIds.includes(value.id)"
                          [disabled]="soldOutAttributesIds.includes(value.id)"
                          [ngClass]="{ 'disabled': soldOutAttributesIds.includes(value.id) }"
                          (change)="setVariant(product.variations, attribute.attribute_values[$any($event.target).value])" />
                      <label [for]="'radio-' + i + '-' + indexx" class="form-check-label">{{ value?.value }}</label>
                  </div>
              </ng-container>
          </div>
      </ng-container>
  </ng-template>
  <ng-template #variantSimple>
      <ul class="circle select-package {{attribute?.style}}" *ngIf="attribute?.style == 'color' else otherVariant">
          <ng-container *ngFor="let value of attribute.attribute_values">
              <li [class.active]="variantIds.includes(value.id) && !soldOutAttributesIds.includes(value.id)"
                  [ngClass]="{ 'disabled': soldOutAttributesIds.includes(value.id) }" placement="top"
                  ngbTooltip="{{value?.value}}" *ngIf="attributeValues?.includes(value.id)">
                  <button (click)="setVariant(product.variations, value)"
                    [ngStyle]="{ 'background-color': value.hex_color }">
                  </button>
              </li>
          </ng-container>
      </ul>
      <ng-template #otherVariant>
          <ul class="select-package {{attribute?.style}}">
              <ng-container *ngFor="let value of attribute.attribute_values">
                  <li [class.active]="variantIds.includes(value.id) && !soldOutAttributesIds.includes(value.id)"
                      [ngClass]="{ 'disabled': soldOutAttributesIds.includes(value.id) }"
                      *ngIf="attributeValues?.includes(value.id)">
                      <img [src]="value?.variation_image ? value?.variation_image?.original_url : 'assets/images/product.png'"
                          (click)="setVariant(product.variations, value)"
                          *ngIf="attribute?.style == 'image' else simple">
                      <ng-template #simple>
                          <app-button
                            [id]="'value_btn'"
                            [type]="'button'"
                            [spinner]="false"
                            (click)="setVariant(product.variations, value)">
                            {{ value?.value }}
                          </app-button>
                      </ng-template>
                  </li>
              </ng-container>
          </ul>
      </ng-template>
  </ng-template>
</ng-container>

<ng-template #passwordModal let-modal>
    <div class="modal-header">
        <h3 class="fw-semibold">{{ 'change_password' | translate }}</h3>
        <app-button
            [class]="'btn btn-close'"
            [spinner]="false"
            [id]="'password_modal_close_btn'"
            (click)="modal.dismiss('Cross click')">
            <i class="ri-close-line"></i>
        </app-button>
    </div>
    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="modal-body address-form">
            <div class="row g-4">
                <!-- <div class="col-xxl-12">
                    <div>
                        <label class="form-label" for="cpass">{{ 'current_password' | translate }}</label>
                        <input class="form-control" id="cpass" type="password" placeholder="{{ 'enter_current_password' | translate }}" formControlName="current_password" />
                        <div class="invalid-feedback" *ngIf="form?.controls?.['current_password']?.touched && form.controls['current_password']?.errors?.['required']">
                            {{ 'current_password_is_required' | translate }}
                        </div>
                    </div>
                </div> -->
                <div class="col-12">
                    <div>
                        <label class="form-label" for="npass">{{ 'new_password' | translate }}</label>
                        <input class="form-control" id="npass" type="password" placeholder="{{ 'enter_new_password' | translate }}" formControlName="password" />
                        <div class="invalid-feedback" *ngIf="form?.controls?.['password']?.touched && form.controls['password']?.errors?.['required']">
                            {{ 'new_password_is_required' | translate }}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div>
                        <label class="form-label" for="cnpass">{{ 'confirm_password' | translate }}</label>
                        <input class="form-control" id="cnpass" type="password" placeholder="{{ 'enter_confirm_password' | translate }}" formControlName="password_confirmation" />
                        <div class="invalid-feedback" *ngIf="form?.controls?.['password_confirmation']?.touched && form.controls['password_confirmation']?.errors?.['required'] else error">
                            {{ 'confirm_password_is_required' | translate }}
                        </div>
                        <ng-template #error>
                            <div class="invalid-feedback" *ngIf="passwordMatchError">
                                {{ 'confirm_password_does_not_matched' | translate }}
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
              <app-button [class]="'btn btn-theme-outline btn-md fw-bold'" [type]="'button'" [spinner]="false"
                [id]="'cancel_profile_btn'" (click)="modal.dismiss('Cancel')">
                {{ 'cancel' | translate }}
              </app-button>
              <app-button [class]="'btn theme-bg-color btn-md fw-bold text-light'" [id]="'submit_profile_btn'">
                {{ 'submit' | translate }}
              </app-button>
            </div>
        </div>
    </form>
</ng-template>

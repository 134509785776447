<ng-template #addressModal let-modal>
  <div class="modal-header">
    <h3 class="fw-semibold"> {{ (!address ? 'add_address' : 'edit_address') | translate  }}</h3>
    <app-button [class]="'btn btn-close'" [spinner]="false" [id]="'address_modal_close_btn'"
      (click)="modal.dismiss('Cross click')">
      <i class="ri-close-line"></i>
    </app-button>
  </div>
  <form [formGroup]="form" class="" (ngSubmit)="submit()">
    <div class="modal-body address-form">
      <div class="row g-4">
        <div class="col-xxl-12">
          <div class="">
            <label class="form-label" for="pname">{{ 'title' | translate }}</label>
            <input class="form-control" id="title" type="text" placeholder="{{ 'enter_title' | translate }}"
              formControlName="title" />
            <div class="invalid-feedback"
              *ngIf="form?.controls?.['title']?.touched && form.controls['title']?.errors?.['required']">
              {{ 'title_is_required' | translate }}
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="">
            <label class="form-label" for="address1">{{ 'address' | translate }}</label>
            <input class="form-control" id="address" type="text" placeholder="{{ 'enter_address' | translate }}"
              formControlName="street" />
            <div class="invalid-feedback"
              *ngIf="form?.controls?.['street']?.touched && form.controls['street']?.errors?.['required']">
              {{ 'street_address_is_required' | translate }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">{{ 'country' | translate }}</label>
            <select2 class="custom-select" [data]="(countries$ | async) ? (countries$ | async)! : []"
              [placeholder]="'select_country' | translate" formControlName="country_id" (update)="countryChange($event)" resettable>
            </select2>
            <div class="invalid-feedback"
              *ngIf="form?.controls?.['country_id']?.touched && form?.controls?.['country_id']?.errors?.['required']">
              {{ 'country_is_required' | translate }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">{{ 'state' | translate }}</label>
            <select2 class="custom-select" [data]="(states$ | async) ? (states$ | async)! : []"
              [placeholder]="'select_state' | translate" formControlName="state_id">
            </select2>
            <div class="invalid-feedback"
              *ngIf="form?.controls?.['state_id']?.touched && form?.controls?.['state_id']?.errors?.['required']">
              {{ 'state_is_required' | translate }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">{{ 'city' | translate }}</label>
            <input class="form-control" id="city" type="text" placeholder="{{ 'enter_city' | translate }}" formControlName="city" />
            <div class="invalid-feedback"
              *ngIf="form?.controls?.['city']?.touched && form.controls['city']?.errors?.['required']">
              {{ 'city_is_required' | translate }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">{{ 'pincode' | translate }}</label>
            <input class="form-control" id="pincode" type="text" placeholder="{{ 'enter_pincode' | translate }}"
              formControlName="pincode" numbersOnly />
            <div class="invalid-feedback"
              *ngIf="form?.controls?.['pincode']?.touched && form.controls['pincode']?.errors?.['required']">
              {{ 'pincode_is_required' | translate }}
            </div>
          </div>
        </div>
        <div class="col-12 phone-field">
          <div class="">
            <label class="form-label" for="address1">{{ 'phone_number' | translate }}</label>
            <select2 class="custom-select intl-tel-input" [templates]="template" [data]="codes"
              formControlName="country_code">
              <ng-template #template let-data="data">
                <div class="country">
                  <div class="flag-box">
                    <div class="iti-flag {{data?.class}}"></div>
                  </div>
                  <span class="dial-code">{{data.code}}</span>
                </div>
              </ng-template>
            </select2>
            <input type="number" class="form-control intl-input-padding" formControlName="phone"
              placeholder="{{ 'enter_phone' | translate }}" name="phone">
            <div class="invalid-feedback"
              *ngIf="form.controls['phone'].touched && form.controls['phone'].errors?.['required']">
              {{ 'phone_number_is_required' | translate }}
            </div>
            <div class="invalid-feedback"
              *ngIf="form.controls['phone'].touched && form.controls['phone'].errors?.['pattern']">
              {{ 'phone_number_is_invalid' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <app-button [class]="'btn btn-theme-outline btn-md fw-bold'" [type]="'button'" [spinner]="false"
          [id]="'cancel_addres_btn'" (click)="modal.dismiss('Cancel')">
          {{ 'cancel' | translate }}
        </app-button>
        <app-button [class]="'btn theme-bg-color btn-md fw-bold text-light'" [id]="'submit_address_btn'">
          {{ 'submit' | translate }}
        </app-button>
      </div>
    </div>
  </form>
</ng-template>

<div class="theme-logo">
    <a [routerLink]="['/']">
        <img [src]="footer.footer_logo" class="img-fluid" alt="logo" *ngIf="footer?.footer_logo else text">
        <ng-template #text>
            <h2 class="f-w-600" *ngIf="data?.general">
                {{ data.general.site_title ? data.general.site_title.split(' ')[0] : 'Logo Here' }}
            </h2>
        </ng-template>
    </a>
</div>

<ng-template #questionModal let-modal>
  <div class="modal-header">
    <h3 class="fw-semibold">{{ 'ask_a_question' | translate }}</h3>
    <app-button
        [class]="'btn btn-close'"
        [spinner]="false"
        [id]="'profile_modal_close_btn'"
        (click)="modal.dismiss('Cross click')">
        <i class="ri-close-line"></i>
    </app-button>
  </div>

  <div class="modal-body">
    <form class="product-review-form">
      <div class="product-wrapper">
        <div class="product-image">
          <img [src]="product.product_thumbnail ?
                product.product_thumbnail.original_url :
                'assets/images/product.png'" class="img-fluid" [alt]="product.name">
        </div>
        <div class="product-content">
          <h5 class="name">{{ product.name }}</h5>
          <div class="product-review-rating">
            <div class="product-rating">
              <h6 class="price-number">{{ product.sale_price | currencySymbol }}</h6>
            </div>
          </div>
        </div>
      </div>

      <div class="review-box">
        <label class="form-label" for="content">{{ 'your_questions' | translate }} *</label>
        <textarea placeholder="{{ 'your_questions' | translate }}" class="form-control" id="content" rows="3" [formControl]="question"></textarea>
      </div>
    </form>
    <div class="modal-footer">
      <app-button [class]="'btn btn-theme-outline btn-md fw-bold'" [type]="'button'" [spinner]="false"
        [id]="'cancel_profile_btn'" (click)="modal.dismiss('Cancel')">{{ 'cancel' | translate }}</app-button>
      <app-button [class]="'btn theme-bg-color btn-md fw-bold text-light'" [id]="'submit_profile_btn'" (click)="submit()">
        {{ 'submit' | translate }}
      </app-button>
    </div>
   </div>
</ng-template>

import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss']
})
export class PaymentOptionsComponent {

}

<div class="social-link" *ngIf="data?.footer?.facebook || data?.footer?.twitter || data?.footer?.instagram || data?.footer?.pinterest">
    <h6 class="text-content">{{ 'stay_connected' | translate }}:</h6>
    <ul>
        <li *ngIf="data?.footer?.facebook">
            <a href="{{ data?.footer?.facebook }}" target="_blank">
                <i class="ri-facebook-fill"></i>
            </a>
        </li>
        <li *ngIf="data?.footer?.twitter">
            <a href="{{ data?.footer?.twitter }}" target="_blank">
                <i class="ri-twitter-fill"></i>
            </a>
        </li>
        <li *ngIf="data?.footer?.instagram">
            <a href="{{ data?.footer?.instagram }}" target="_blank">
                <i class="ri-instagram-fill"></i>
            </a>
        </li>
        <li *ngIf="data?.footer?.pinterest">
            <a href="{{ data?.footer?.pinterest }}" target="_blank">
                <i class="ri-pinterest-fill"></i>
            </a>
        </li>
    </ul>
</div>

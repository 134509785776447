<!-- Category Style Vertical -->
<div class="category-menu section-b-space" *ngIf="categories?.length && style == 'vertical'">
  <h3>{{ title ? title : 'categories' | translate }}</h3>
  <ul>
    <li *ngFor="let category of categories">
      <div class="category-list">
        <img [src]="category?.category_icon ? category?.category_icon?.original_url : 'assets/images/category.png'"
             [alt]="category?.name">
        <h5>
          <a href="javascript:void(0)" (click)="redirectToCollection(category.slug!)">{{ category?.name }}</a>
        </h5>
      </div>
    </li>
  </ul>
</div>

<!-- Category Classic Vertical -->
<div class="shop-left-sidebar" *ngIf="categories?.length && style == 'classic_vertical'">
  <ul class="nav nav-pills mb-3 custom-nav-tab">
    <li class="nav-item" *ngFor="let category of categories">
      <app-button
        [class]="selectedCategorySlug.length && selectedCategorySlug.includes(category.slug!) ? 'nav-link active' : 'nav-link'"
        [id]="'nav_link_btn'"
        [spinner]="false"
        (click)="redirectToCollection(category.slug!)">
        {{ category.name }}
        <img [src]="category?.category_icon ? category?.category_icon?.original_url : 'assets/images/category.png'"
             [alt]="category?.name">
      </app-button>
    </li>
  </ul>
</div>

<!-- Category Style Horizontal -->
<div class="product-wrapper no-arrow" [class]="'category-slider'" *ngIf="categories?.length && style == 'horizontal'">
  <owl-carousel-o [options]="sliderOption">
    <ng-template carouselSlide *ngFor="let category of categories">
      <div *ngIf="theme == 'paris'">
        <a href="javascript:void(0)"
          (click)="redirectToCollection(category.slug!)"
          class="category-box category-dark"
          [class.active]="selectedCategorySlug.length && selectedCategorySlug.includes(category.slug!)">
          <img [src]="image ? image : 'assets/images/shape.png'" class="background-image" alt="shape">
          <img [src]="category?.category_icon ? category?.category_icon?.original_url : 'assets/images/category.png'"
               [alt]="category?.name">
          <h5>{{ category.name }}</h5>
        </a>
      </div>
      <div *ngIf="theme == 'madrid'">
        <div class="category-box-list">
          <a href="javascript:void(0)" (click)="redirectToCollection(category.slug!)" class="category-name">
              <h4>{{ category.name }}</h4>
              <h6>{{ category.products_count }} {{ 'items' | translate }}</h6>
          </a>
          <div class="category-box-view">
            <a href="javascript:void(0)" (click)="redirectToCollection(category.slug!)">
              <img [src]="category?.category_image ? category?.category_image?.original_url : 'assets/images/category.png'"
                   class="img-fluid"
                   [alt]="category?.name">
            </a>
            <app-button [class]="'btn shop-button'" [id]="'shop_now_btn'" [spinner]="false">
              <span>{{ 'shop_now' | translate }}</span>
              <i class="ri-arrow-right-s-line"></i>
            </app-button>
          </div>
        </div>
      </div>
      <div *ngIf="theme == 'rome'">
        <div class="shop-category-box border-0">
            <a href="javascript:void(0)" (click)="redirectToCollection(category.slug!)" class="circle-1">
              <img [src]="category?.category_image ? category?.category_image?.original_url : 'assets/images/category.png'"
                   [alt]="category?.name">
            </a>
            <div class="category-name">
              <h6>{{ category.name }}</h6>
            </div>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
</div>

<!-- Category Style classic -->
<div *ngIf="categories?.length && style == 'classic'">
  <owl-carousel-o [options]="sliderOption">
    <ng-template carouselSlide *ngFor="let category of categories">
      <div>
        <div class="shop-category-box border-0 wow fadeIn">
            <a href="javascript:void(0)" (click)="redirectToCollection(category.slug!)" class="circle-1">
              <img [src]="category?.category_icon ? category?.category_icon?.original_url : 'assets/images/category.png'"
                   [alt]="category?.name">
            </a>
            <div class="category-name">
              <h6>{{ category.name }}</h6>
            </div>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
</div>

<!-- Category Style Filter -->
<ul class="nav nav-tabs tab-style-color-2 tab-style-color" *ngIf="categories?.length && style == 'filter'">
  <li class="nav-item" *ngFor="let category of categories">
    <app-button
      [class]="selectedCategoryId == category.id ? 'nav-link btn active' : 'nav-link btn' "
      [spinner]="false"
      [id]="'category'+category.id"
      (click)="selectCategory(category.id)">
      {{category?.name}}
    </app-button>
  </li>
</ul>

<!--- Category Style Dropdown -->
<ul class="category-list" *ngIf="categories?.length && style == 'dropdown'">
  <li class="onhover-category-list" *ngFor="let category of categories">
    <a href="javascript:void(0)" (click)="redirectToCollection(category.slug!)" class="category-name">
      <img [src]="category?.category_icon ? category?.category_icon?.original_url : 'assets/images/category.png'" [alt]="category?.name">
      <h6>{{ category?.name }}</h6>
    </a>
  </li>
</ul>

<!-- Input Category Dropdown -->
<app-button
  [class]="'btn location-button'"
  [id]="'category_btn'"
  [spinner]="false"
  [type]="'button'"
  *ngIf="categories?.length && style == 'input_dropdown'">
 <select class="form-select locat-name">
    <option>{{ 'all_category' | translate }}</option>
    <option *ngFor="let category of categories">
      {{ category.name }}
    </option>
  </select>
</app-button>

<ng-template #payModal let-modal>
  <div class="modal-header">
    <h3 class="fw-semibold">{{ 'pay_now' | translate }}</h3>
    <app-button
        [class]="'btn btn-close'"
        [spinner]="false"
        [id]="'profile_modal_close_btn'"
        (click)="modal.dismiss('Cross click')">
        <i class="ri-close-line"></i>
    </app-button>
  </div>

  <div class="modal-body">
    <div class="checkout-box">
      <div class="checkout-detail">
        <div class="row g-3">
            <ng-container *ngFor="let payment of (setting$ |async)?.payment_methods; let i = index;">
            <div class="col-md-6" *ngIf="payment.status">
                <div class="payment-option">
                    <div class="payment-category w-100">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="payment_method" [value]="payment.name" [id]="payment.name" [formControl]="paymentType">
                            <label class="form-check-label" for="{{payment.name}}">
                                {{ payment.name | uppercase }}
                            </label>
                        </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="invalid-feedback" *ngIf="paymentType?.touched && paymentType.errors?.['required']">
                {{ 'select_payment_method_is_required' | translate }}
              </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <app-button [class]="'btn btn-theme-outline btn-md fw-bold'" [type]="'button'" [spinner]="false"
        [id]="'cancel_profile_btn'" (click)="modal.dismiss('Cancel')">{{ 'cancel' | translate }}</app-button>
      <app-button [class]="'btn theme-bg-color btn-md fw-bold text-light'" [id]="'submit_profile_btn'" (click)="submit()">
        {{ 'submit' | translate }}
      </app-button>
    </div>
  </div>
</ng-template>

<ng-template #refundModal let-modal>
  <div class="modal-header">
    <h3 class="fw-semibold">{{ 'refund' | translate }}</h3>
    <app-button
        [class]="'btn btn-close'"
        [spinner]="false"
        [id]="'profile_modal_close_btn'"
        (click)="modal.dismiss('Cross click')">
        <i class="ri-close-line"></i>
    </app-button>
  </div>

  <div class="modal-body">
    <form class="product-review-form">
      <div class="product-wrapper">
        <div class="product-image">
          <img [src]="product.product_thumbnail ?
                product.product_thumbnail.original_url :
                'assets/images/product.png'" class="img-fluid" [alt]="product.name">
        </div>
        <div class="product-content">
          <h5 class="name">{{ product.name }}</h5>
          <div class="product-review-rating">
            <div class="product-rating">
              <h6 class="price-number">{{ product.sale_price | currencySymbol }}</h6>
            </div>
          </div>
        </div>
      </div>

      <div class="review-box">
        <form [formGroup]="form">
          <label class="form-label" for="content">{{ 'reason' | translate }} *</label>
          <textarea placeholder="{{ 'enter_reason' | translate }}" class="form-control" id="content" rows="3" formControlName="reason"></textarea>
          <div class="invalid-feedback" *ngIf="form?.controls?.['reason']?.touched && form?.controls?.['reason']?.errors?.['required']">
            {{ 'reason_is_required' | translate }}
          </div>
          <label class="form-label mt-3" for="address1">{{ 'Payment Option' | translate }}</label>
          <select2 class="custom-select"
              [data]="option ? option : []"
              [placeholder]="'select_payment_option' | translate"
              formControlName="payment_type"
              resettable>
          </select2>
          <div class="invalid-feedback" *ngIf="form?.controls?.['payment_type']?.touched && form?.controls?.['payment_type']?.errors?.['required']">
              {{ 'payment_type_is_required' | translate }}
          </div>
        </form>
      </div>
    </form>
    <div class="modal-footer">
      <app-button [class]="'btn btn-theme-outline btn-md fw-bold'" [type]="'button'" [spinner]="false"
        [id]="'cancel_profile_btn'" (click)="modal.dismiss('Cancel')">{{ 'cancel' | translate }}</app-button>
      <app-button [class]="'btn theme-bg-color btn-md fw-bold text-light'" [id]="'submit_profile_btn'" (click)="sendRequest()">
        {{ 'submit' | translate }}
      </app-button>
    </div>
  </div>
</ng-template>

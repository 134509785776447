import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AccountUser } from "../interface/account.interface";
import { ActivatedRoute, Router } from "@angular/router";
import { json } from "express";

@Injectable({
  providedIn: "root",
})
export class AccountService {

  constructor(private http: HttpClient,private route: ActivatedRoute,private router: Router) { }

  httpOption = {
    headers : new HttpHeaders({
      'Content-Type' : 'application/json',
      // 'Authorization': `bearer ${this.tokenGet}`
      
    })
  }


  userRegister(data:any){
    return this.http.post(`${environment.apiUrl}Customer/addUser`,data,this.httpOption);
  }

  userlogin(data:any){
    return this.http.post(`${environment.apiUrl}Customer/loginapi/`,data,this.httpOption);
  }

  setToken(token:any){
    localStorage.setItem('token',token)
  }

  setUserData(data:any){
    localStorage.setItem('user',JSON.stringify(data))
  }

  getUserDetails(): Observable<AccountUser> {
    return this.http.get<AccountUser>(`${environment.URL}/account.json`);
  }

  profileUpdate(id:any,data:any){
    return this.http.put(`${environment.apiUrl}Customer/updateUser/${id}`,data,this.httpOption);
  }

  getUserData(){
    const details : any = localStorage.getItem('user')
    return JSON.parse(details);
  }

  delUserData(){
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  }

  changePassword(data:any){
    return this.http.post(`${environment.apiUrl}Customer/change-password/`,data,this.httpOption);
  }

}

<ng-template #sizeChartModal let-modal>
  <div class="modal-header">
      <h3 class="fw-semibold">{{ 'size_chart' | translate }}</h3>
      <app-button
          [class]="'btn btn-close'"
          [spinner]="false"
          [id]="'profile_modal_close_btn'"
          (click)="modal.dismiss('Cross click')">
          <i class="ri-close-line"></i>
      </app-button>
  </div>
  <div class="modal-body">
    <img src="{{ image.original_url }}" class="img-fluid" alt="chart">
  </div>
</ng-template>

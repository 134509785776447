<div class="product-box" [class]="class">
    <div class="label-tag sale-tag" *ngIf="product.is_sale_enable else featured"><span>{{ 'sale' | translate  }}</span></div>
    <ng-template #featured>
        <div class="label-tag" *ngIf="product.is_featured"><span>{{ 'featured' | translate  }}</span></div>
    </ng-template>
    <div class="product-image">
        <a [routerLink]="['/product/', product.slug]">
            <img [src]="product.product_thumbnail ?
                        product.product_thumbnail.original_url :
                        'assets/images/product.png'" class="img-fluid" [alt]="'product'">
        </a>
        <div class="product-header-top" *ngIf="close">
          <app-button
            [class]="'btn wishlist-button close_button'"
            [spinner]="false"
            [id]="'close_btn'"
            (click)="removeWishlist(product.id)">
              <i class="ri-close-line"></i>
          </app-button>
        </div>
        <ul class="product-option" *ngIf="!close">
            <li>
                <a href="javascript:void(0)" (click)="productDetailModal.openModal()">
                    <i class="ri-eye-line"></i>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" (click)="addToCompar(product.id)">
                    <i class="ri-refresh-line"></i>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" class="notifi-wishlist" (click)="addToWishlist(product.id)">
                    <i class="ri-heart-line"></i>
                </a>
            </li>
        </ul>
    </div>
    <div class="product-detail">
        <a [routerLink]="['/product/', product.slug]">
            <h6 class="name">{{ product.name }}</h6>
        </a>
        <p>{{ product.short_description }}</p>
        <h6 class="unit mt-1" *ngIf="product.unit">{{ product.unit }}</h6>
        <h5 class="sold text-content">
            <span class="theme-color price">{{ product.sale_price | currencySymbol }}</span>
            <del *ngIf="product.discount">{{ product.price | currencySymbol }}</del>
        </h5>
        <div class="product-rating">
            <ngb-rating [rate]="product.rating_count"></ngb-rating>
            <h6 class="theme-color" *ngIf="product.stock_status == 'in_stock'">
                {{ product.stock_status | titleCase }}
            </h6>
        </div>
        <div class="add-to-cart-box">
            <app-button [id]="'add-to-cart'+product.id"
                [class]="'btn btn-add-cart addcart-button'"
                [iconClass]="'ri-add-line'"
                [spinner]="false"
                (click)="product.type === 'classified' ? productDetailModal.openModal() : addToCart(product, 1)"
                *ngIf="product.stock_status == 'in_stock' else outStock">
                {{ 'add' | translate  }}
                <span class="add-icon">
                    <i class="ri-add-line"></i>
                </span>
            </app-button>
            <ng-template #outStock>
                <app-button [id]="'out-of-stock'+product.id"
                    [class]="'btn btn-add-cart addcart-button'"
                    [spinner]="false"
                    [disabled]="true">
                    {{ 'sold_out' | translate  }}
                </app-button>
            </ng-template>
            <div class="cart_qty qty-box" [class.open]="cartItem && cartItem.quantity > 0">
                <div class="input-group">
                    <app-button
                        [id]="'qty-left-minus'+product.id"
                        [spinner]="false"
                        [class]="'qty-left-minus'"
                        (click)="addToCart(product, -1)">
                        <i class="ri-subtract-line"></i>
                    </app-button>
                    <input class="form-control input-number qty-input" type="text" name="quantity"
                        value="{{cartItem && cartItem.quantity ? cartItem.quantity : cartItem}}"
                        readonly>
                    <app-button
                        [id]="'qty-right-plus'+product.id"
                        [spinner]="false"
                        [class]="'qty-left-plus'"
                        (click)="addToCart(product, 1)">
                        <i class="ri-add-fill"></i>
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</div>
<app-product-detail-modal #productDetailModal [product]="product"></app-product-detail-modal>
<app-variation-modal #variationModal></app-variation-modal>

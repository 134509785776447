<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<section class="section-404 section-lg-space">
    <div class="container-fluid-lg">
        <div class="row">
            <div class="col-12">
                <div class="image-404">
                    <img src="assets/images/inner-page/404.png" class="img-fluid" alt="error 404">
                </div>
            </div>
            <div class="col-12" *ngIf="(themeOption$ | async)?.error_page?.error_page_content">
                <div class="contain-404">
                    <h3 class="text-content">
                        {{ (themeOption$ | async)?.error_page?.error_page_content }}
                    </h3>
                    <app-button
                      [class]="'btn btn-md text-white theme-bg-color mt-4 mx-auto'"
                      [id]="'back_button'"
                      [spinner]="false"
                      *ngIf="(themeOption$ | async)?.error_page?.back_button_enable"
                      (click)="back()">
                      {{ (themeOption$ | async)?.error_page?.back_button_text }}
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</section>
